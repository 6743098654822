import { useState } from "react";
import IconService from "../../services/icon_service";
import ApiIntegrationModal from "./api_integration_modal";
import Button from "../../common/button";
import SearchInput from "../../common/search-input";

const _DATA = [
	{
		id: 1,
		name: "Northern Trust Google Admin API",
		type: "Google",
		statusIcon: "Warning",
		statusMessage: "No Credentials",
		typeName: "Google Workspace",
	},
	{
		id: 2,
		name: "South Shields AD API",
		type: "Azure",
		statusIcon: "Success",
		statusMessage: "Token acquired",
		typeName: "Azure AD",
	},
	{
		id: 3,
		name: "Wonde API",
		type: "Azure",
		statusIcon: "Error",
		statusMessage: "Failed to refresh",
		typeName: "Wonde",
	},
];

const iconService = new IconService();

function Configurations() {
	const [isFirstLoad, setFirstLoad] = useState<boolean>(false);
	const [isApiModalOpen, setIsApiModalOpen] = useState<boolean>(false);

	const showViews = () => {
		isFirstLoad ? setFirstLoad(false) : setFirstLoad(false);
	};

	const createNewIntegration = () => {
		setIsApiModalOpen(true);
	};

	const closeModal = () => {
		setIsApiModalOpen(false);
	};

	const btnStyle = {
		display: "flex",
		alignItems: "center",
		padding: "8px 12px",
	};

	return (
		<div id='api_config'>
			<div className='col-12'>
				<ApiIntegrationModal
					isOpen={isApiModalOpen}
					onRequestClose={closeModal}
				/>
				<div className='col-12'>
					<h3>API Integrations</h3>
					<p>
						Create API integrations which allows Utropolis to display directory
						structures, authenticate web traffic and contextualise reports.
					</p>
				</div>
				<div className='apiContentContainer'>
					<div className='container-fluid'>
						<div className='row d-flex align-items-center'>
							<div className='col-12  col-md-3 col-lg-6'>
								<SearchInput placeholder='Search' />
							</div>
							<div
								className='col-12 col-md-9 col-lg-6'
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<Button
									type='primary'
									onClick={() => createNewIntegration()}
									style={{ ...btnStyle, marginRight: "10px" }}
								>
									<span>{iconService.getIcon("Plus")}</span>
									Create New Integration
								</Button>
								<Button style={btnStyle} type='secondary'>
									<span>{iconService.getIcon("Refresh")}</span>
									Refresh Connection
								</Button>
							</div>
						</div>
						{isFirstLoad ? (
							<div className='col-12 center-content'>
								{iconService.getIcon("Cloud", "apiCloud")}
								<h4>No API Integrations Yet!</h4>
								<p>
									You need to integrate one of your Directory APIs to enable us
									to display your directory structures and authenticate your
									users web traffic.
								</p>
								<Button type='primary' onClick={() => createNewIntegration()}>
									Create a New Integration
								</Button>
							</div>
						) : (
							<div className='row'>
								<table className='table table-striped mt-3'>
									<thead>
										<tr>
											<th className='wid-5 tableHeading'></th>
											<th className='wid-40 tableHeading'>Name</th>
											<th className='wid-25 tableHeading'>Type</th>
											<th className='wid-25 tableHeading'>Status</th>
											<th className='wid-5 tableHeading'></th>
										</tr>
									</thead>
									<tbody>
										{_DATA.map((item) => (
											<tr key={item.id}>
												<td>
													<input className='form-check-input' type='checkbox' />
												</td>

												<td>{item.name}</td>
												<td>
													<div className='d-flex align-items-center'>
														{iconService.getIcon(item.type)}
														<span style={{ marginLeft: "0.5rem" }}>
															{item.typeName}
														</span>
													</div>
												</td>
												<td>
													<div className='d-flex align-items-center'>
														{iconService.getIcon(item.statusIcon)}
														<span style={{ marginLeft: "0.5rem" }}>
															{item.statusMessage}
														</span>
													</div>
												</td>
												<td>{iconService.getIcon("Dots")}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Configurations;
