interface SubMenuProps {
	id: string;
	imgSrc: string;
	altText: string;
	text: string;
	items?: { text: string; navigate: string }[];
	openSubmenus: Record<string, boolean>;
	toggleSubmenu: (id: string) => void;
	navigate: any;
	navigateTo: string;
	onClick: () => void;
	className: string;
}

const SubMenu = ({
	id,
	imgSrc,
	altText,
	text,
	items,
	openSubmenus,
	toggleSubmenu,
	navigate,
	navigateTo,
	onClick,
	className,
}: SubMenuProps) => (
	<li style={{ marginBottom: "1rem" }} onClick={onClick} className={className}>
		<a
			href={`#${id}`}
			data-bs-toggle='collapse'
			onClick={() => toggleSubmenu(id)}
		>
			<div
				className='d-flex align-items-center'
				onClick={() => navigate(navigateTo)}
			>
				<img
					alt={altText}
					className='nav-icon-img'
					src={require(`../../../assets/${imgSrc}`)}
				/>
				<span className='ms-2 menu-text'>{text}</span>
			</div>
		</a>

		{/* ==== DO NOT DELETE These are arrow up/down icons might be used in future=== */}

		{/* <div className='menu-image'>
				<img
					alt='chevron icon'
					className='nav-icon-img ml-auto d-flex justify-content-end '
					src={
						openSubmenus[id]
							? require("../../../assets/chevron-up.png")
							: require("../../../assets/chevron-down.png")
					}
				/>
			</div> */}
		{/* ==== DO NOT DELETE These are for submenu's do not delete might be used in future=== */}

		{/* <ul className='' id={id} data-bs-parent='#menu'>
			{items?.map((item, index) => (
				<li key={index} className='w-100 text-center'>
					<button
						className='nav-link px-0'
						onClick={() => navigate(item.navigate)}
					>
						<span className='d-none d-sm-inline ml-3 text-color-primary'>
							{item.text}
						</span>
					</button>
				</li>
			))}
		</ul> */}
	</li>
);
export default SubMenu;
