import React from "react";
import Modal from "react-modal";
import IconService from "../../services/icon_service";
import Button from "../../common/button";

interface ModalContentProps {
	isOpen: boolean;
	onRequestClose: () => void;
}

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "400px", // Set the width to 400px
		borderRadius: "var(--fs-20)",
	},
};

const iconService = new IconService();

const ApiIntegrationModal: React.FC<ModalContentProps> = ({
	isOpen,
	onRequestClose,
}) => {
	return (
		<div>
			<Modal isOpen={isOpen} style={customStyles} contentLabel='Modal'>
				<div className='modal-header'>
					<label className='label mb-2'>Create New API Integration</label>
					<div className='close-icon' onClick={onRequestClose}>
						{iconService.getIcon("Close")}
					</div>
				</div>

				<p>
					Please fill out the required information and select an integration
					type. We will then attempt to make a connection when you click the
					‘save’ button.
				</p>

				<div className='form-group'>
					<label htmlFor='name' className='mb-2 mt-2'>
						Name
					</label>
					<input
						type='text'
						className='form-control'
						id='name'
						placeholder='name'
					/>
				</div>
				<div className='form-group'>
					<label htmlFor='type' className='mb-2 mt-2'>
						Type
					</label>
					<input type='text' className='form-control' id='type' />
				</div>
				<div className='form-group'>
					<label htmlFor='json' className='mb-2 mt-2'>
						Json
					</label>
					<textarea
						rows={6}
						className='form-control'
						id='json'
						placeholder='place your json here'
					/>
				</div>
				<div className='modal-footer mt-4'>
					<div className=''>
						<Button type='primary'>Save</Button>
					</div>

					<div className='col text-end'>
						<Button
							type='primary'
							style={{
								backgroundColor: "var(--semantic-red)",
								color: "var(--white)",
							}}
						>
							Delete
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ApiIntegrationModal;
