import { useEffect, useState } from "react";
import IconService from "../../../services/icon_service";
import { ReactComponent as Play } from "../../../assets/play.svg";
import Button from "../../../common/button";
import { NameData, WebData, _DATA, _DATA_WEB_LOG } from "./dummy-data";
import SteamTable from "./stream_table";
import Select from "../../../common/select";

const iconService = new IconService();

function WebLogStream() {
	const [searchQuery, setSearchQuery] = useState("");
	const [names, setNames] = useState<NameData[]>([]);
	const [selectedNameId, setSelectedNameId] = useState<number>();
	const [webLogData, setWebLogData] = useState<WebData[]>([]);
	const [startStream, setStartStream] = useState<boolean>(false);

	useEffect(() => {
		setWebLogData(_DATA_WEB_LOG);
		setSelectedNameId(-1);
		setSearchQuery("");
		setTimeout(() => {
			setNames(_DATA);
		}, 1000);
	}, []);

	const handleSelectChange = (event: any) => {
		setSelectedNameId(Number(event.target.value));
		setWebLogData([]);
		setSearchQuery("");
	};

	const getUserLogStream = () => {
		const filteredLogs: WebData[] = _DATA_WEB_LOG.filter(
			(log) => log.userId === selectedNameId
		);
		setWebLogData(filteredLogs);
	};

	const disconnectUserLogStream = () => {
		setWebLogData([]);
		setSelectedNameId(-1);
	};

	const pauseUserLogStream = () => {};

	const handleSearchChange = (event: any) => {
		const query = event.target.value;
		setSearchQuery(query);

		const filtered = _DATA_WEB_LOG.filter(
			(log) =>
				(selectedNameId == null || log.userId === selectedNameId) &&
				(log.user.toLowerCase().includes(query.toLowerCase()) ||
					log.action.toLowerCase().includes(query.toLowerCase()) ||
					log.policy.toLowerCase().includes(query.toLowerCase()) ||
					log.url.toLowerCase().includes(query.toLowerCase()))
		);

		setWebLogData(filtered);
	};

	return (
		<div id='weblogstream_config'>
			<div className='col-12'>
				<div className='col-12'>
					<h3>Weblog Stream</h3>
				</div>
				<div className='webLogStreamContentContainer mt-4'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-2 col-md-4 col-sm-3'>
								<Select
									options={names}
									value={selectedNameId || 1}
									onChange={handleSelectChange}
									label='Select a user'
									disabledOptionLabel='Please select'
									style={{
										width: "180px !important",
										borderRadius: "8px",
										display: "flex",
										padding: "10px 12px 10px 16px",
										alignItems: "flex-start",
									}}
								/>
							</div>
							<div className='col-6 col-md-8 col-sm-9'>
								<div className='connectionButtonsContainer'>
									{startStream ? (
										<>
											<div className='d-flex button-no-padding'>
												<Button
													type='primary'
													onClick={() => pauseUserLogStream()}
													style={{
														display: "flex",
														padding: "12px 24px",

														justifyContent: "center",
														alignItems: "center",
														borderRadius: "12px",
													}}
												>
													<span>{iconService.getIcon("Pause")}</span>
													Pause
												</Button>

												<Button
													style={{
														marginLeft: "1rem",
														backgroundColor: "var(--semantic-red-100)",
														display: "flex",
														padding: "12px 24px",

														justifyContent: "center",
														alignItems: "center",
														borderRadius: "12px",
													}}
													type='primary'
													onClick={() => disconnectUserLogStream()}
												>
													<span>{iconService.getIcon("Disconnect")}</span>
													Stop User Logs Stream{" "}
												</Button>
											</div>
										</>
									) : (
										<>
											<Button
												type='primary'
												onClick={() => setStartStream(true)}
												style={{
													display: "flex",
													padding: "12px 24px",
													justifyContent: "center",
													alignItems: "center",
													borderRadius: "12px",
												}}
												disabled={selectedNameId !== -1 ? false : true}
											>
												<Play
													style={{
														fill: selectedNameId === -1 ? "#141616" : "white",
														width: "1.5rem",
														height: "1.5rem",
														stroke: selectedNameId === -1 ? "#141616" : "white",
														marginRight: "0.5rem",
													}}
												/>
												Stream User Logs
											</Button>
										</>
									)}
								</div>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-6'>
								<div className='input-group'>
									<div className='form-outline searchBoxIntegrationContainer'>
										<label className='label mb-2'>Weblog Search</label>

										<div className='input-group mb-3 position-relative align-items-center'>
											<span
												className='position-absolute'
												style={{
													left: "10px",
													top: "52%",
													zIndex: "999",
													transform: "translateY(-50%)",
												}}
											>
												{iconService.getIcon("Search")}
											</span>{" "}
											<input
												style={{ paddingLeft: "var(--fs-40)" }}
												className='form-control searchBoxIntegration '
												type='search'
												placeholder='Search'
												value={searchQuery}
												onChange={handleSearchChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='col-3 mt-1'>
								<div className='form-group'>
									<label htmlFor='filters' className='label mb-2'>
										Filters
									</label>
									<input
										type='text'
										placeholder='Filters'
										className='form-control'
										id='filters'
									/>
								</div>
							</div>
						</div>
						<SteamTable {...{ webLogData, startStream, selectedNameId }} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default WebLogStream;
