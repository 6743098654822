import axios from "axios";

const baseUrl = process.env.REACT_APP_QA_BASE_URL;
const fetchWebLogs = async (customerId: number, email: string) => {
	const res = await fetch(
		`weblogs/stream?customerId=${customerId}&user=${email}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	console.log({ res });
};

const signIn = async (email: string, password: string) => {
	const data = `email=${encodeURIComponent(
		email
	)}&password=${encodeURIComponent(password)}`;

	const config = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	};

	const res = await axios.post(`${baseUrl}/login`, data, config);
	console.log({ res });
};

export { fetchWebLogs, signIn };
