import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import AdminSidebar from "./admin/components/admin_sidebar";
import AdminPanel from "./components/adminPanel/admin_panel";
import AuthenticationMethods from "./components/dashboard/authentication_methods";
import Configurations from "./components/dashboard/configurations";
import Dashboard from "./components/dashboard/dashboard";
import WebLogStream from "./components/dashboard/weblog";
import HelpCentre from "./components/help_centre";
import ApiIntegration from "./components/integration/api_integration";
import Login from "./components/login";
import Notifications from "./components/notifications";
import BlockPage from "./pages/blockpage";
import "./scss/custom-bootstrap.scss";
import IconService from "./services/icon_service";
import LocalStorageService from "./services/local_storage_service";
import SidebarV2 from "./components/dashboard/sidebar/sidebar_v2";

function App() {
	const [isAdminSidebarVisible, setIsAdminSidebarVisible] =
		useState<boolean>(false);
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const iconService = new IconService();
	const handleLogin = () => {
		setIsLoggedIn(true);
		setIsAdminSidebarVisible(false);
		LocalStorageService.setItem("isLoggedIn", true);
	};

	// useEffect(() => {
	// 	const isLoggedInLocalStorage =
	// 		LocalStorageService.getItem<boolean>("isLoggedIn");
	// 	if (isLoggedInLocalStorage !== null) {
	// 		setIsLoggedIn(isLoggedInLocalStorage);
	// 	}
	// }, []);
	useEffect(() => {
		const token = LocalStorageService.getItem<string>("token");
		if (token !== null) {
			setIsLoggedIn(true);
		}
	}, [isLoggedIn]);

	return (
		<div className='app'>
			<BrowserRouter>
				{isLoggedIn ? (
					<>
						<div className='row content'>
							<div className='row headerContainer'>
								<div className='row'>
									<div
										className='col-2 headerLogo'
										onClick={() => setIsAdminSidebarVisible(false)}
									>
										{iconService.getIcon("Logo", "logo-img")}
									</div>
									<div className='col-8 headerItems'>
										<div className='row'>
											<div className='col-3'>
												<label className='label mt-2 text-center'>
													Select your school
												</label>
											</div>
											<div className='col-6'>
												<select
													name='school'
													id='schools'
													className='navSelection'
												>
													<option
														value='-1'
														className='navSelectionOption'
														disabled
													>
														Please select school
													</option>
													<option
														value='school 1'
														className='navSelectionOption'
													>
														School 1
													</option>
													<option
														value='school 2'
														className='navSelectionOption'
													>
														School 2
													</option>
													<option
														value='school 3'
														className='navSelectionOption'
													>
														School 3
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div
									className='col-lg-2 col-md-2 col-sm-12'
									style={{ maxWidth: "250px" }}
								>
									{isAdminSidebarVisible ? (
										<AdminSidebar
											{...{ setIsAdminSidebarVisible, setIsLoggedIn }}
										/>
									) : (
										<SidebarV2
											{...{ setIsAdminSidebarVisible, setIsLoggedIn }}
										/>
									)}
								</div>
								<div className='col-lg-10 col-md-10 col-sm-12 p-5 p-md-4'>
									<Routes>
										<Route path='/' element={<Dashboard />} />
										<Route path='/web-log-stream' element={<WebLogStream />} />
										<Route
											path='/authentication-methods'
											element={<AuthenticationMethods />}
										/>
										<Route
											path='/configurations'
											element={<Configurations />}
										/>
										<Route path='/notifications' element={<Notifications />} />
										<Route
											path='/api-integration'
											element={<ApiIntegration />}
										/>
										<Route path='/admin-panel' element={<AdminPanel />} />
										<Route path='/help-centre' element={<HelpCentre />} />
										<Route path='/blocked' element={<BlockPage />} />
										<Route path='/login' element={<Login />} />
									</Routes>
								</div>
							</div>
						</div>
					</>
				) : (
					<Routes>
						<Route path='/' element={<Login {...{ handleLogin }} />} />
					</Routes>
				)}
			</BrowserRouter>
		</div>
	);
}

export default App;
