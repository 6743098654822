import React from "react";
import Select from "../../../common/select";

const SchoolSelector = () => {
	return (
		<div className='navSelectionContainer navItem'>
			<Select
				options={[
					{ id: 1, name: "School 1" },
					{ id: 2, name: "School 2" },
				]}
				value={1}
				onChange={() => console.log("test")}
				label='Select your school'
				disabledOptionLabel='Please select'
				style={{
					width: "180px !important",
					borderRadius: "8px",
					display: "flex",
					padding: "10px 12px 10px 16px",
					alignItems: "flex-start",
				}}
			/>
		</div>
	);
};

export default SchoolSelector;
