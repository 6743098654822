import { useNavigate } from "react-router-dom";
import "../../../components/dashboard/sidebar/sidebar_v2.scss";

import "../../../components/dashboard/sidebar/sidebar.scss";
import SidebarLogo from "../../../components/dashboard/sidebar/sidebar_logo";
import AdminMenu from "../admin_menu";

interface AdminSidebarProps {
	setIsAdminSidebarVisible: (isSidebarVisible: boolean) => void;
	setIsLoggedIn: (isLoggedIn: boolean) => void;
}
function AdminSidebar({
	setIsAdminSidebarVisible,
	setIsLoggedIn,
}: AdminSidebarProps) {
	const navigate = useNavigate();

	return (
		<div className='sidebar'>
			<div className='row logo-sideBar'>
				<SidebarLogo />
			</div>

			<div
				className='d-flex align-items-center text-center mb-4 mt-3 px-4'
				onClick={() => setIsAdminSidebarVisible(false)}
			>
				<div style={{ paddingRight: "10px" }}>
					<img
						src={require("../../../assets/shield_lock.png")}
						alt='shield-lock-icon'
					/>
				</div>
				<span className='menu-text'>Filtering</span>
			</div>

			<AdminMenu {...{ navigate, setIsLoggedIn }} />
		</div>
	);
}

export default AdminSidebar;
