import React, { useState } from "react";
import SubMenu from "../../components/dashboard/sidebar/sub_menu";
import MenuItem from "../../components/dashboard/sidebar/MenuItem";
import LocalStorageService from "../../services/local_storage_service";

const subMenus = [
	{
		id: "submenu1",
		imgSrc: "cog.png",
		altText: "cog png",
		text: "Administration",
		items: [
			{ text: "Operational", navigate: "/notifications" },
			{ text: "Configuration", navigate: "/web-log-stream" },
		],
		navigateTo: "/",
	},

	{
		id: "submenu2",
		imgSrc: "transit-connection-variant.png",
		altText: "transit-connection-variant.png",
		text: "API Integrations",
		items: [{ text: "Operational", navigate: "/notifications" }],
		navigateTo: "/configurations",
	},

	{
		id: "submenu3",
		imgSrc: "account-group.png",
		altText: "account-group.png",
		text: "User Management",
		navigateTo: "/web-log-stream",
	},
	{
		id: "submenu4",
		imgSrc: "school.png",
		altText: "school png",
		text: "School Management",
		navigateTo: "/web-log-stream",
	},
	{
		id: "submenu5",
		imgSrc: "fingerprint.png",
		altText: "fingerprint png",
		text: "Authentication",
		navigateTo: "/authentication-methods",
	},
];
const AdminMenu = ({ navigate, setIsLoggedIn }: any) => {
	const [openSubmenus, setOpenSubmenus] = useState<Record<string, boolean>>({});
	const [activeMenu, setActiveMenu] = useState<string | null>(null);

	const toggleSubmenu = (submenuId: string) => {
		setOpenSubmenus((prevState) => ({
			...Object.keys(prevState).reduce(
				(acc, key) => ({ ...acc, [key]: key === submenuId }),
				{}
			),
			[submenuId]: !openSubmenus[submenuId],
		}));
	};

	const handleLogout = () => {
		setIsLoggedIn(false);
		LocalStorageService.setItem("isLoggedIn", false);
		LocalStorageService.removeItem("token");
		navigate("/");
	};
	return (
		<>
			<div className='top-menu'>
				{subMenus?.map((subMenu) => (
					<SubMenu
						key={subMenu.id}
						id={subMenu.id}
						imgSrc={subMenu.imgSrc}
						altText={subMenu.altText}
						text={subMenu.text}
						items={subMenu.items}
						{...{ openSubmenus, toggleSubmenu, navigate }}
						navigateTo={subMenu.navigateTo}
						onClick={() => setActiveMenu(subMenu.id)}
						className={subMenu.id === activeMenu ? "active" : ""}
					/>
				))}
			</div>

			<div className='spacer'></div>

			<div className='bottom-menu'>
				<MenuItem
					altText='dashboard png'
					imgSrc={require("../../assets/help.png")}
					text='Help Center'
				/>
				<MenuItem
					imgSrc={require("../../assets/Avatar_Image.png")}
					altText='hugenerd'
					text='John Smith'
					rightImgSrc={require("../../assets/logout.png")}
					onClick={handleLogout}
				/>
			</div>
		</>
	);
};

export default AdminMenu;
