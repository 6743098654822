import React from "react";
import Modal from "react-modal";
import IconService from "../../services/icon_service";
import Button from "../../common/button";

interface ModalContentProps {
	isOpen: boolean;
	onRequestClose: () => void;
}

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

const iconService = new IconService();

const ConfigureAuthenticationMethodModal: React.FC<ModalContentProps> = ({
	isOpen,
	onRequestClose,
}) => {
	return (
		<div>
			<Modal isOpen={isOpen} style={customStyles} contentLabel='Modal'>
				<div className='modal-header'>
					<label className='label mb-2'>Configure Authentication Method</label>
					<div className='close-icon' onClick={onRequestClose}>
						{iconService.getIcon("Close")}
					</div>
				</div>

				<div className='form-group mt-4'>
					<label htmlFor='name' className='mb-2 mt-2'>
						Name
					</label>
					<input type='text' className='form-control' id='name' />
				</div>
				<div className='form-group'>
					<label htmlFor='type' className='mb-2 mt-2'>
						Type
					</label>
					<input type='text' className='form-control' id='type' />
				</div>

				<div className='form-check form-switch mt-3 mx-2'>
					<input
						className='form-check-input custom-switch'
						type='checkbox'
						id='status'
					/>
					<label htmlFor='type' className='mx-2'>
						Disabled
					</label>
				</div>

				<div className='mt-5'>
					<Button type='primary'>Save</Button>
					<button
						type='button'
						className='btn-customContentPlainModal mx-2'
						onClick={onRequestClose}
					>
						Cancel
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default ConfigureAuthenticationMethodModal;
