export interface NameData {
	id: number;
	name: string;
}

export const _DATA: NameData[] = [
	{ id: 1, name: "Jenny Taylor" },
	{ id: 2, name: "Mike Smith" },
	{ id: 3, name: "Heather Wallace" },
	{ id: 4, name: "Amelia Walker" },
	{ id: 5, name: "Benjamin Martinez" },
	{ id: 6, name: "Chloe Thompson" },
	{ id: 7, name: "Dylan Reynolds" },
	{ id: 8, name: "Emily Carter" },
	{ id: 9, name: "Finnegan Hughes" },
	{ id: 10, name: "Grace Anderson" },
	{ id: 11, name: "Henry Mitchell" },
	{ id: 12, name: "Isabella Turner" },
	{ id: 13, name: "Jacob Williams" },
	{ id: 14, name: "Kayla Johnson" },
	{ id: 15, name: "Liam Harris" },
	{ id: 16, name: "Mia Davis" },
	{ id: 17, name: "Olivia Smit" },
	{ id: 18, name: "Penelope Adams" },
	{ id: 19, name: "Samuel Wilson" },
	{ id: 20, name: "Taylor White" },
];

export interface WebData {
	id: number;
	level: number;
	url: string;
	action: string;
	timeStamp: Date;
	policy: string;
	user: string;
	userId: number;
	type: string;
	category: string;
}

export const _DATA_WEB_LOG: WebData[] = [
	{
		id: 1,
		level: 5,
		url: "www.example.com",
		action: "Block",
		timeStamp: new Date(),
		policy: "1. Adult Content",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 2,
		level: 4,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "2. YouTube Education",
		user: "Jenny Taylor",
		userId: 1,
		type: "CDN",
		category: "Safeguarding Alert ",
	},
	{
		id: 3,
		level: 4,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 4,
		level: 3,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		category: "Safeguarding Alert ",

		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
	},
	{
		id: 5,
		level: 4,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 6,
		level: 1,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 7,
		level: 5,
		url: "www.example.com",
		action: "Block",
		timeStamp: new Date(),
		policy: "1. Adult Content",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 8,
		level: 5,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 9,
		level: 5,
		url: "www.example.com",
		action: "Block",
		timeStamp: new Date(),
		policy: "1. Adult Content",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 10,
		level: 3,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 11,
		level: 5,
		url: "www.example.com",
		action: "Block",
		timeStamp: new Date(),
		policy: "1. Adult Content",
		user: "Jenny Taylor",
		userId: 1,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 12,
		level: 5,
		url: "www.example.com",
		action: "Block",
		timeStamp: new Date(),
		policy: "1. Adult Content",
		user: "Mike Smith",
		userId: 2,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 13,
		level: 3,
		url: "www.example.com",
		action: "Allow",
		timeStamp: new Date(),
		policy: "1. YouTube Education",
		user: "Mike Smith",
		userId: 2,
		type: "Authenticated",
		category: "Safeguarding Alert ",
	},
	{
		id: 14,
		level: 5,
		url: "www.example.com",
		action: "Block",
		timeStamp: new Date(),
		policy: "1. Adult Content",
		user: "Mike Smith",
		userId: 2,
		type: "Authenticated",
		category: "Entertainment",
	},
];
