import React from "react";

interface pill {
	text: string;
	bgColor: string;
	textColor?: string;
}
const Pills = ({ text, bgColor, textColor }: pill) => {
	return (
		<div
			className='pill'
			style={{
				borderRadius: "20px",
				padding: "5px",
				backgroundColor: bgColor,
				fontSize: text.length + "px",
				color: textColor ? textColor : "#000",
			}}
		>
			{text}
		</div>
	);
};

export default Pills;
