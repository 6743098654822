import { useEffect, useRef, useState } from "react";
import Button from "../../../common/button";
import IconService from "../../../services/icon_service";
import NotSelected from "./NotSelected";
import { fetchWebLogs } from "../../../services/service";
import { apiV1 } from "../../../constant";
const iconService = new IconService();

interface Event {
	_source: {
		trafficType: string;
		timestamp: number;
		path: string;
		contentCategory: string;
		filterPolicyId: string;
		filterAction: string;
	};
	_id: string;
}
const SteamTable = ({ selectedNameId = 1, startStream }: any) => {
	const [events, setEvents] = useState<Event[]>([]);
	const [error, setError] = useState<string | null>(null);
	const eventSource = useRef<any | null>(null);

	const handleEvent = (event: MessageEvent) => {
		const parsedData: Event = JSON.parse(event.data);
		setEvents((prevEvents) => {
			const newEvents = [parsedData, ...prevEvents];
			newEvents.sort((a, b) => b._source.timestamp - a._source.timestamp);
			return newEvents;
		});
	};

	useEffect(() => {
		if (!startStream) {
			return;
		}

		if (eventSource.current) {
			eventSource.current.close();
		}
		const url = `https://qa-app.utropolis.io${apiV1}/weblogs/stream?customerId=${selectedNameId}&user=john.doe@email.com`;
		eventSource.current = new EventSource(url);

		eventSource.current.onopen = (event: any) =>
			console.log("Connection opened", event);
		eventSource.current.onerror = (event: any) => {
			if (eventSource.current.readyState !== EventSource.CLOSED) {
				setTimeout(() => {
					console.log("Reconnecting...");
					eventSource.current = new EventSource(url);
				}, 5000); // Reconnect after 5 seconds
			} else {
				setError("EventSource failed.");
			}
		};
		eventSource.current.onmessage = handleEvent;

		return () => {
			eventSource.current.close();
		};
	}, [startStream, selectedNameId]);
	return (
		<div className='row'>
			<table className='table table-striped mt-3 rounded-table'>
				<thead>
					<tr>
						<th className='wid-10 tableHeading text-center'>Traffic Type</th>
						<th className='wid-10 tableHeading  text-center'>Timestamp</th>
						<th className='wid-10 tableHeading  text-center'>Url</th>
						<th className='wid-10 tableHeading  text-center'>Category</th>
						<th className='wid-10 tableHeading  text-center'>Policy</th>
						<th className='wid-10 tableHeading text-center'>Action</th>
					</tr>
				</thead>
				<tbody>
					{events.length > 0 ? (
						events.map((item: any, index) => {
							const { _source, _id } = item;

							const typeColor =
								item.level === 5
									? "var(--clr-primary-100)"
									: "var(--semantic-yellow-100)";

							const categoryColor =
								item.category === "Safeguarding Alert "
									? "var(--semantic-red-100)"
									: "var(--semantic-yellow-100)";
							return (
								<tr key={index} className='align-middle table-row'>
									{" "}
									<td className='fw-700' style={{ textAlign: "center" }}>
										<Button
											type='primary'
											style={{
												backgroundColor: typeColor,
												color: "var(--neutral-black)",
												fontSize: "var(--fs-12)",
												fontWeight: "700",
											}}
										>
											{_source.trafficType}
										</Button>
									</td>
									<td
										className='text-center fw-700'
										style={{ textAlign: "center" }}
									>
										{new Date(_source.timestamp).toLocaleString()}
									</td>
									<td className='fw-700' style={{ textAlign: "center" }}>
										{_source.host}
									</td>
									<td style={{ textAlign: "center" }}>
										<Button
											type='primary'
											style={{
												backgroundColor: categoryColor,
												fontSize: "var(--fs-12)",
												fontWeight: "700",
											}}
										>
											{_source.contentCategory}
										</Button>
									</td>
									<td className='fw-700' style={{ textAlign: "center" }}>
										{_source.filterPolicyId}
									</td>
									<td className='fw-700'>
										<div className='d-flex align-items-center justify-content-center'>
											{iconService.getIcon(item.action)}
											<span style={{ marginLeft: "0.5rem" }}>
												{_source.filterAction}
											</span>
										</div>
									</td>
								</tr>
							);
						})
					) : (
						<td className='text-center align-middle' colSpan={12}>
							<NotSelected />
						</td>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default SteamTable;
