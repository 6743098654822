import React from "react";

class IconService {
	getIcon(type: string, customClass?: string): JSX.Element | null {
		switch (type) {
			case "Logo":
				return (
					<img
						src={require("../assets/logo_sidebar.png")}
						alt='Logo'
						className={customClass}
					/>
				);
			case "Google":
				return (
					<>
						<img src={require("../assets/Google.png")} alt='Google' />{" "}
						{/* <label>Google Workspace</label>{" "} */}
					</>
				);
			case "Azure":
				return (
					<>
						<img src={require("../assets/microsoft-azure.png")} alt='Azure' />{" "}
						{/* <label>Azure AD</label>{" "} */}
					</>
				);
			case "Warning":
				return (
					<img
						src={require("../assets/alert-circle-outline.png")}
						alt='Warning'
					/>
				);
			case "Success":
				return <img src={require("../assets/check.png")} alt='Success' />;
			case "Error":
				return <img src={require("../assets/alert-outline.png")} alt='Error' />;
			case "Dots":
				return <img src={require("../assets/dots-vertical.png")} alt='Dots' />;
			case "Close":
				return <img src={require("../assets/Close.png")} alt='Close' />;
			case "Allow":
				return <img src={require("../assets/allow.png")} alt='Allow' />;
			case "Block":
				return <img src={require("../assets/block.png")} alt='Block' />;
			case "Disconnect":
				return (
					<img src={require("../assets/Disconnect.png")} alt='Disconnect' />
				);
			case "Cloud":
				return (
					<img
						src={require("../assets/cloud_connection.png")}
						alt='Cloud'
						className={customClass}
					/>
				);

			case "Pause":
				return <img src={require("../assets/pause.png")} alt='"Pause' />;

			case "Plus":
				return <img src={require("../assets/plus.png")} alt='Plus' />;
			case "Refresh":
				return <img src={require("../assets/refresh.png")} alt='Refresh' />;
			case "Search":
				return <img src={require("../assets/Search.png")} alt='Search' />;
			default:
				return <label>{type}</label>;
		}
	}
}

export default IconService;
