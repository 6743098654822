import { ChangeEvent, useEffect, useState } from "react";
import IconService from "../../services/icon_service";
import ConfigureAuthenticationMethodModal from "./configure_authentication_method_modal";
import Button from "../../common/button";
import Select from "../../common/select";
import SearchInput from "../../common/search-input";

interface AuthMethodData {
	id: number;
	name: string;
	type: string;
	status: boolean;
}

const _authMethodData: AuthMethodData[] = [
	{
		id: 1,
		name: "Northern Trust Google Admin API",
		type: "Google",
		status: false,
	},
	{ id: 2, name: "South Shields AD API", type: "Azure", status: false },
	{ id: 3, name: "Wonde API", type: "Wonde", status: true },
];

const iconService = new IconService();

function AuthenticationMethods() {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [authMethods, setAuthMethods] = useState<AuthMethodData[]>();

	useEffect(() => {
		setTimeout(() => {
			setAuthMethods(_authMethodData);
		}, 1000);
	}, []);

	const createAuthMethod = () => {
		setIsModalOpen(true);
	};

	const refreshAuthMethod = () => {};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const toggleStatus = (item: AuthMethodData) => {
		const updatedData = authMethods?.map((dataItem) => {
			if (dataItem.id === item.id) {
				return { ...dataItem, status: !dataItem.status };
			}
			return dataItem;
		});
		setAuthMethods(updatedData);
	};

	const btnStyle = {
		display: "flex",
		alignItems: "center",
		padding: "8px 12px",
	};
	return (
		<div id='auth_config'>
			<div className='col-12'>
				<div className='col-12'>
					<ConfigureAuthenticationMethodModal
						isOpen={isModalOpen}
						onRequestClose={closeModal}
					/>
					<h3>Authentication Methods</h3>
					<p>
						You can create authentication methods based on API integrations that
						you’ve setup previously. Existing Methods are displayed below.
					</p>
				</div>
				<div className='authMethodContentContainer'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12  col-md-3 col-lg-6'>
								<SearchInput placeholder='Search' style={{ width: "100%" }} />
							</div>
							<div
								className='col-12 col-md-9 col-lg-6'
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<Button
									type='primary'
									onClick={() => createAuthMethod()}
									style={{ ...btnStyle, marginRight: "10px" }}
								>
									<span className='icon'>{iconService.getIcon("Plus")}</span>
									Create New Integration
								</Button>
								<Button
									type='secondary'
									style={btnStyle}
									onClick={() => refreshAuthMethod()}
								>
									<span className='icon'>{iconService.getIcon("Refresh")}</span>
									Refresh Connection
								</Button>
							</div>
						</div>
						<div className='row p-100 '>
							<table className='table table-striped mt-3'>
								<thead>
									<tr>
										<th className='wid-5 tableHeading'></th>
										<th className='wid-35 tableHeading'>Name</th>
										<th className='wid-35 tableHeading'>Type</th>
										<th className='wid-20 tableHeading'>Status</th>
										<th className='wid-10 tableHeading'></th>
									</tr>
								</thead>
								<tbody>
									{authMethods?.map((item) => (
										<tr key={item.id}>
											<td>
												<input className='form-check-input' type='checkbox' />
											</td>
											<td>
												<label className='label'>{item.name}</label>
											</td>
											<td>
												<label className='label'>
													No Authentication Methods Connected
													{/* {iconService.getIcon(item.type)} */}
												</label>
											</td>

											<td>
												<div
													className='form-check form-switch mx-2'
													key={item.id}
												>
													<input
														className='form-check-input custom-switch'
														type='checkbox'
														id={`status-${item.id}`}
														checked={item.status}
														onChange={() => toggleStatus(item)}
													/>
													<label
														htmlFor={`status-${item.id}`}
														className='label mx-2'
													>
														{!item.status ? "Disabled" : "Enabled"}
													</label>
												</div>
											</td>

											<td>{iconService.getIcon("Dots")}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AuthenticationMethods;
