import React, { useState } from "react";

import "./sidebar_v2.scss";
import SchoolSelector from "./school_selector";
import SubMenu from "./sub_menu";
import SidebarLogo from "./sidebar_logo";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../../services/local_storage_service";
import MenuItem from "./MenuItem";
const subMenus = [
	{
		id: "submenu1",
		imgSrc: "dashboard.png",
		altText: "dashboard png",
		text: "Dashboard",
		items: [
			{ text: "Operational", navigate: "/notifications" },
			{ text: "Configuration", navigate: "/web-log-stream" },
		],
		navigateTo: "/",
	},

	// {
	// 	id: "submenu2",
	// 	imgSrc: "policies.png",
	// 	altText: "policies png",
	// 	text: "Policies",
	// 	items: [{ text: "Operational", navigate: "/notifications" }],
	// 	navigateTo: "/",
	// },

	{
		id: "submenu3",
		imgSrc: "config.png",
		altText: "configuration png",
		text: "Stream Weblogs",
		navigateTo: "/web-log-stream",
	},
	// {
	// 	id: "submenu4",
	// 	imgSrc: "transit-connection-variant.png",
	// 	altText: "connection png",
	// 	text: "Integration",
	// 	navigateTo: "/web-log-stream",
	// },
];

const SidebarV2 = ({ setIsAdminSidebarVisible, setIsLoggedIn }: any) => {
	const [openSubmenus, setOpenSubmenus] = useState<Record<string, boolean>>({});
	const [activeMenu, setActiveMenu] = useState<string | null>("submenu1");

	const toggleSubmenu = (submenuId: string) => {
		setOpenSubmenus((prevState) => ({
			...Object.keys(prevState).reduce(
				(acc, key) => ({ ...acc, [key]: key === submenuId }),
				{}
			),
			[submenuId]: !openSubmenus[submenuId],
		}));
	};
	const navigate = useNavigate();

	const handleLogout = () => {
		setIsLoggedIn(false);
		LocalStorageService.setItem("isLoggedIn", false);
		LocalStorageService.removeItem("token");
		navigate("/");
	};

	return (
		<div className='sidebar'>
			<div className='row logo-sideBar'>
				<SidebarLogo />
			</div>
			<div
				className='d-flex align-items-center text-center mb-4 mt-3 px-4 cursor-pointer'
				style={{ cursor: "pointer" }}
				onClick={() => navigate("/notifications")}
			>
				<div style={{ paddingRight: "10px" }}>
					<img
						src={require("./../../../assets/shield_lock.png")}
						alt='shield-lock-icon'
					/>
				</div>
				<span className='menu-text'>Filtering</span>
			</div>
			<div
				className='d-flex justify-content-center
            '
			>
				<SchoolSelector />
			</div>
			<div className='top-menu'>
				{subMenus?.map((subMenu) => (
					<SubMenu
						key={subMenu.id}
						id={subMenu.id}
						imgSrc={subMenu.imgSrc}
						altText={subMenu.altText}
						text={subMenu.text}
						items={subMenu.items}
						{...{ openSubmenus, toggleSubmenu, navigate }}
						navigateTo={subMenu.navigateTo}
						onClick={() => setActiveMenu(subMenu.id)}
						className={subMenu.id === activeMenu ? "active" : ""}
					/>
				))}
			</div>

			<div className='spacer'></div>

			<div className='bottom-menu'>
				<MenuItem
					imgSrc={require("../../../assets/cog.png")}
					altText='dashboard png'
					text='Administration'
					onClick={() => setIsAdminSidebarVisible(true)}
				/>
				<MenuItem
					altText='dashboard png'
					imgSrc={require("../../../assets/help.png")}
					text='Help Center'
				/>
				<MenuItem
					imgSrc={require("../../../assets/Avatar_Image.png")}
					altText='hugenerd'
					text='John Smith'
					rightImgSrc={require("../../../assets/logout.png")}
					onClick={handleLogout}
				/>
			</div>
		</div>
	);
};

export default SidebarV2;
