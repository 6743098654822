import React from "react";

interface InputFieldProps {
	label: string;
	type: string;
	id: string;
	style?: React.CSSProperties;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
}

const inputStyle = {
	padding: "12px 24px",
};

const InputField: React.FC<InputFieldProps> = ({
	label,
	type,
	id,
	style,
	onChange,
	value,
}) => {
	return (
		<div className='form-group' style={{ ...style }}>
			<label htmlFor={id}>{label}</label>
			<input
				onChange={onChange}
				type={type}
				className='form-control'
				id={id}
				style={{
					...inputStyle,
				}}
				value={value}
			/>
		</div>
	);
};

export default InputField;
