import Pills from "../../common/pills";
import CloudImg from "../../assets/cloud_connection 1.png";
import "./blockpage.scss";
import Button from "../../common/button";

const BlockPage = () => {
	return (
		<>
			<div className='container'>
				<p className='block-title col-md-8'>
					Looks like this page has been blocked
				</p>
				<div className='row'>
					<div className='col-md-6 mx-auto d-flex justify-content-center align-items-center'>
						<img src={CloudImg} alt='banner' className='img-fluid' />
					</div>
					<div className='col-md-6'>
						<div>
							<h2 className='sub-title'>Why has this page been blocked?</h2>
							<p>
								<strong>URL:</strong> youtube/education.com
							</p>
							<p>
								<strong>Block Reason:</strong> Social Media
							</p>
							<p style={{ display: "flex", textAlign: "center" }}>
								<strong>Filter Category:</strong>{" "}
								<Pills text='Social Media' bgColor='#FFC700' />
							</p>
							<p>
								<strong>Policy responsible for blocking:</strong> Adult Content
								Block Policy
							</p>
						</div>

						<div>
							<h2 className='sub-title'>Your Details?</h2>
							<p>
								<strong>Username:</strong> John Snow
							</p>
							<p style={{ display: "flex", textAlign: "center" }}>
								<strong>Filter group:</strong>{" "}
								<Pills text='Social Media' bgColor='#BEFADE' />
							</p>
							<p>
								<strong>Ip Address:</strong> 59::88::62:189
							</p>
							<p>
								<strong>Date and Time:</strong> 05.08.2023 at 15:13
							</p>
							<p>Feel like this page has been blocked by mistake?</p>
							<Button type='secondary' onClick={() => {}}>
								Ask for it to be unblocked
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlockPage;
