import React, { FC, ChangeEvent, CSSProperties } from "react";

interface SelectProps {
	options: { id: number; name: string }[];
	value: number;
	onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
	label: string;
	disabledOptionLabel: string;
	style?: CSSProperties;
}

const Select: FC<SelectProps> = ({
	options,
	value,
	onChange,
	label,
	disabledOptionLabel,
	style,
}) => {
	return (
		<>
			<label className='label mb-2'>{label}</label>
			<select
				className='form-select'
				aria-label={label}
				onChange={onChange}
				value={value}
				style={style}
			>
				<option value='-1' disabled>
					{disabledOptionLabel}
				</option>
				{options.map((item) => (
					<option key={item.id} value={item.id}>
						{item.name}
					</option>
				))}
			</select>
		</>
	);
};

export default Select;
