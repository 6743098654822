class LocalStorageService {
    static setItem(key: string, value: any): void {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error("Error setting item in local storage:", error);
      }
    }
  
    static getItem<T>(key: string): T | null {
      try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
      } catch (error) {
        console.error("Error getting item from local storage:", error);
        return null;
      }
    }
  
    static removeItem(key: string): void {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error("Error removing item from local storage:", error);
      }
    }
  
    static clear(): void {
      try {
        localStorage.clear();
      } catch (error) {
        console.error("Error clearing local storage:", error);
      }
    }
  }
  
  export default LocalStorageService;
  