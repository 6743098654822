function Dashboard() {
	const src =
		"https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4";

	return (
		<video controls width='100%'>
			<source src={src} type='video/mp4' />
			Sorry, your browser doesn't support embedded videos.
		</video>
	);
}

export default Dashboard;
