import { useState } from "react";
import Button from "../common/button";
import InputField from "../common/input";
import { signIn } from "../services/service";
import GoogleLoginButton from "./auth/GoogleSignIn";

const btnStyle = {
	display: "flex",
	alignItems: "center",
	padding: "12px 24px",
	borderRadius: "12px",
};

function Login({ handleLogin }: any) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	return (
		<div className='loginContainer'>
			<div className='row'>
				<div className='col-md-12 signInContainer col-lg-6'>
					<div className='signInChildContainer'>
						<div className='row'>
							<h2 className='text-center'>Sign In</h2>
						</div>
						<div className='row pt-5'>
							<InputField
								label='Email'
								onChange={(e) => setEmail(e.target.value)}
								type='email'
								id='email'
								value={email}
							/>
						</div>
						<div className='row pt-4'>
							<InputField
								onChange={(e) => setPassword(e.target.value)}
								label='Password'
								type='password'
								id='password'
								value={password}
							/>
						</div>
						<div className='row pt-5'>
							<div className='form-group'>
								<Button
									disabled={!email || !password}
									type='primary'
									style={{ width: "100%", ...btnStyle }}
									onClick={() => signIn(email, password)}
									// onClick={handleLogin}
								>
									Sign In
								</Button>
							</div>
						</div>
						<div className='row pt-4'>
							<div className='form-group'>
								<GoogleLoginButton handleLogin={handleLogin} />
							</div>
						</div>
						<div className='row accountPadding'>
							<label className='text-center'>
								Already have an account ?{" "}
								<a href='/' className='loginLink'>
									Log In
								</a>
							</label>
						</div>
					</div>
				</div>
				<div className='col-lg-6 reviewContainer'>
					<div className='reviews'>
						<div className='row g-0 p-4 reviewStars'>
							<div className='flex-center'>
								{[...Array(5)].map((_, index) => (
									<div key={index} className=''>
										<img
											src={require("../assets/Vector.png")}
											alt={`star ${index}`}
											className='m-1'
										/>
									</div>
								))}
							</div>
						</div>
						<div className='col p-4'>
							<label className='quoteText text-center'>
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse varius enim in eros elementum tristique. Duis
								cursus, mi quis viverra ornare, eros dolor interdum nulla."
							</label>
						</div>
						<div className='col  flex-center'>
							<img src={require("../assets/Avatar_Image.png")} alt='avatar' />
							<label className='quoteText mx-4 '>
								<div className='reviewName'>Name Surname</div>
								<div className='mt-2'>Position, Company name</div>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
