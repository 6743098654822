import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

interface GoogleLoginButtonProps {
	handleLogin: () => void;
}
const GoogleLoginButton = ({ handleLogin }: GoogleLoginButtonProps) => {
	const handleSuccess = (credentialResponse: any) => {
		if (credentialResponse.credential !== null) {
			localStorage.setItem("token", credentialResponse.credential);
			handleLogin();
		} else {
			alert("Please try again");
		}
	};

	const handleError = () => {
		alert("Login Failed");
	};

	const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	return (
		<GoogleOAuthProvider clientId={clientId || ""}>
			<div className='flex-center'>
				<div className='idp-login'>
					<div className='google'>
						<GoogleLogin onSuccess={handleSuccess} onError={handleError} />
					</div>
				</div>
			</div>
		</GoogleOAuthProvider>
	);
};

export default GoogleLoginButton;
