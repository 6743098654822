import React, { CSSProperties } from "react";
import IconService from "../../services/icon_service";

const iconService = new IconService();

interface SearchInputProps {
	placeholder?: string;
	style?: CSSProperties;
}

const SearchInput: React.FC<SearchInputProps> = ({
	placeholder = "Search",
	style,
}) => {
	return (
		<div style={{ width: "100%", ...style }}>
			<div className='form-outline searchBoxIntegrationContainer'>
				<div className='input-group position-relative align-items-center'>
					<span
						className='position-absolute'
						style={{
							left: "10px",
							top: "52%",
							zIndex: "999",
							transform: "translateY(-50%)",
						}}
					>
						{iconService.getIcon("Search")}
					</span>{" "}
					<input
						style={{ paddingLeft: "var(--fs-40)" }}
						className='form-control searchBoxIntegration '
						type='search'
						placeholder={placeholder}
					/>
				</div>
			</div>
		</div>
	);
};

export default SearchInput;
